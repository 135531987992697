// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'theme-variables.scss';
@import 'light-theme.scss';
@import 'dark-theme.scss';

$asap-typography: mat.define-typography-config(
  $font-family: '"Asap", sans-serif',
);
@include mat.typography-hierarchy($asap-typography);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($oph-primary),
      accent: mat.define-palette($oph-accent),
    ),
    typography: $asap-typography,
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette($oph-primary),
      accent: mat.define-palette($oph-accent),
    ),
    typography: $asap-typography,
  )
);

@include mat.all-component-themes($light-theme);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
}
