.mat-mdc-autocomplete-panel {
  min-width: unset !important;
  border-radius: 12px !important;
  min-height: 1px !important;
  max-height: calc(50vh - 20px);
  max-width: none !important;
  padding: 0 !important;

  .mat-mdc-option {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    // max-width: 464px;
    padding: 8px 18px;
    border-bottom: 1px solid #e5e3dc;
    line-height: 20px;
    box-sizing: border-box;
    min-width: 100px;
    min-height: 50px;

    &:last-child {
      border-bottom: none;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--off-black);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.mat-mdc-autocomplete-panel::-webkit-scrollbar {
  width: 3px;
}

.mat-mdc-autocomplete-panel::-webkit-scrollbar-track {
  background-color: #c7c2b6;
  border-radius: 14px;
}

.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb {
  background-color: #7c655f;
  border-radius: 14px;
}

// For options with icons, wrap mat-option in oph-autocomplete-option
.oph-autocomplete-option {
  display: flex;
  align-items: center;
  padding-right: 18px;
  border-bottom: 1px solid var(--border);

  .mat-mdc-option {
    border-bottom: none !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
