@import 'src/styles/_variables';

.timepicker-ui-wrapper {
  width: 380px !important;
  border-radius: 10px !important;

  .timepicker-ui-header {
    padding-top: 24px;
    height: 86px;

    .timepicker-ui-select-time {
      display: none;
    }

    .timepicker-ui-wrapper-time {
      .timepicker-ui-hour,
      .timepicker-ui-minutes {
        color: var(--ophanim-final-brown-med);
        border-radius: 6px;
        border: 2px solid var(--ophanim-final-border);
        background: #f4f4f4;
      }

      .timepicker-ui-hour:hover,
      .timepicker-ui-minutes:hover {
        background-color: var(--white);
        border: 2px solid var(--orange);
      }

      .active,
      .active:focus {
        background-color: var(--white);
        border-color: var(--orange) !important;
      }

      .timepicker-ui-dots {
        color: var(--ophanim-final-brown-med);
      }
    }

    .timepicker-ui-wrapper-type-time {
      color: var(--ophanim-final-brown-med);
      border-radius: 6px;
      border: 2px solid var(--ophanim-final-border);
      background: #f4f4f4;
      padding: 3px;
      height: 73px;

      .timepicker-ui-am,
      .timepicker-ui-pm {
        border: none;
        width: 34px;
        border-radius: 6px;
      }

      .timepicker-ui-am:hover,
      .timepicker-ui-pm:hover {
        color: #f4f4f4;
        background-color: var(--ophanim-final-brown-med);
      }

      .active {
        color: #f4f4f4;
        background-color: var(--ophanim-final-brown-med);
      }
    }
  }

  .timepicker-ui-wrapper-landspace {
    .timepicker-ui-body {
      .timepicker-ui-clock-face {
        color: var(--ophanim-final-brown-med);
        // border: 2px solid var(--ophanim-final-border); // Throws off the clock numbers... need to sort out
        background: #f4f4f4;

        .timepicker-ui-dot {
          background-color: var(--ophanim-final-brown-med);
        }

        .timepicker-ui-clock-hand {
          background-color: var(--ophanim-final-brown-med);

          .timepicker-ui-circle-hand {
            background-color: var(--ophanim-final-brown-med);
          }
        }
      }
    }

    .timepicker-ui-footer {
      .timepicker-ui-wrapper-btn {
        margin-right: 25px;

        .timepicker-ui-cancel-btn {
          color: var(--ophanim-final-brown-med);
          text-transform: capitalize;
        }

        .timepicker-ui-ok-btn {
          color: var(--white);
          background-color: var(--dark-green);
          border-radius: 10px;
        }
      }
    }
  }
}

.ngx-slider {
  .ngx-slider-pointer {
    background: #7c655f !important;
  }

  .ngx-slider-pointer:after {
    background: #faf7ed !important;
  }

  .ngx-slider-bar {
    background: #7c655f !important;
  }

  .ngx-slider-tick {
    background: #7c655f !important;
  }
}
