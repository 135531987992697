.subtask-list-container {
  // DESCRIPTION IN CURRENT SKED DETAIL
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-expansion-panel {
    background: var(--lightest);
    border-radius: 8px;
    transition: ease 0.3s;
  }

  .mat-expansion-panel-header {
    padding: 12px 15px;
    display: flex;
    height: auto;
  }

  .mat-expansion-panel-header-title {
    color: var(--brown-med);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: auto;
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: var(--white);
  }

  .mat-expansion-panel-body {
    padding: 0 15px 16px 15px;

    p {
      margin-bottom: 0;
      color: var(--light-theme-foreground-87, rgba(47, 39, 35, 0.87));
      font-size: 16px;
      line-height: 24px;
    }
  }

  // SUBTASK IN CURRENT SKED DETAIL
  .subtask-container {
    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-expansion-panel {
      background: var(--lightest);
      border-radius: 8px;
      transition: ease 0.3s;
    }

    .mat-expansion-panel-header {
      padding: 0;
      display: flex;
      height: 60px;
    }

    .mat-expansion-panel-header-title {
      color: #4a433e;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-width: 0;
      flex-grow: 1;
      margin-right: 6px;
    }

    .mat-expansion-panel-header.mat-expanded {
      height: 60px;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background: var(--white);
    }

    .mat-content.mat-content-hide-toggle {
      margin-right: 0;
    }

    .mat-expansion-panel-body {
      padding: 0 15px 16px 15px;

      p {
        margin-bottom: 0;
        color: var(--light-theme-foreground-87, rgba(47, 39, 35, 0.87));
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
