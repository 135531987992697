$black-87-opacity: rgba(black, 0.87);
$background-color: #d8d3c6;

$oph-primary: (
  50: #faf7ed,
  100: #f0ecdd,
  200: #d9d6c8,
  300: #bfb299,
  400: #a6a498,
  500: #5d4037,
  600: #52342b,
  700: #45261d,
  800: #402218,
  900: #321911,
  A100: #fefdf5,
  A200: #fcf3c5,
  A400: #ffec8f,
  A700: #ffe875,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);

$oph-accent: (
  50: #ffe3b8,
  100: #ffc16b,
  200: #ffae52,
  300: #fa953d,
  400: #fb8b41,
  500: #fa7428,
  600: #f06719,
  700: #e06824,
  800: #c75d20,
  900: #c64f0b,
  A100: #ffffff,
  A200: #fff6f0,
  A400: #ffdecb,
  A700: #ffceb2,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  ),
);

@mixin light-theme-colors {
  --darker: #2f2723;
  --dark: #5d4037;
  --brown-med: #7c655f;
  --gold: #bca863;
  --red: #e95757;
  --alt-red: #f65959;
  --orange: #eb7a3b;
  --alt-orange: #f57b4e;
  --yellow: #d6d046;
  --green: #93e256;
  --dark-green: #7bc143;
  --dark-green-hover: #64aa2c;
  --blue: #55cbbd;
  --tan-med: #f0ecdd;
  --light: #faf7ed;
  --lightest: #fefdf5;
  --white: #ffffff;
  --border: #e5e3dc;
  --grey-light-2: #e2ded5;
  --background: #d8d3c6;
  --scrollbar: #c7c2b6;
  --grey-light: #a3a099;
  --grey-med: #7a746f;
  --off-black: #4a443d;
  --template-bg: #f8ecec;
  --template-border: #dcbcd9;
  --template: #9a5b92;
  --lavender: #b6bcf2;
  --dark-lavender: #6960cb;
  --sky-blue: #e4f0ec;
  --light-green: #ebf0e4;

  --auto-dark: #35a4ab;
  --auto-border: #92dfd6;
  --auto-bg: #ebf0e4;
  --non-auto-bg: var(--light);
  --auto-team-border: var(--lavender);
  --auto-team-bg: var(--sky-blue);
  --auto-user-bg: var(--light-green);
  --sked-color-block: var(--dark-lavender);

  --nav-selected-text: var(--dark);
  --nav-selected-bckrnd: var(--tan-med);
  --nav-deselected-text: var(--brown-med);

  --ophanim-final-light: var(--light);
  --ophanim-final-auto-border: var(--auto-border);
  --ophanim-final-auto-bg: var(--light-green);
  --ophanim-final-dark: var(--dark);
  --ophanim-final-brown-med: var(--brown-med);
  --ophanim-final-border: var(--border);
  --ophanim-final-orange: var(--alt-orange);
  --ophanim-final-red: var(--alt-red);
}

@mixin dark-theme-colors {
  --darker: #2f2723;
  --dark: #5d4037;
  --brown-med: #7c655f;
  --gold: #bca863;
  --red: #e95757;
  --alt-red: #f65959;
  --orange: #eb7a3b;
  --alt-orange: #f57b4e;
  --yellow: #d6d046;
  --green: #93e256;
  --dark-green: #7bc143;
  --dark-green-hover: #64aa2c;
  --blue: #55cbbd;
  --tan-med: #f0ecdd;
  --light: #faf7ed;
  --lightest: #fefdf5;
  --white: #ffffff;
  --border: #e5e3dc;
  --grey-light-2: #e2ded5;
  --background: red;
  --scrollbar: #c7c2b6;
  --grey-light: #a3a099;
  --grey-med: #7a746f;
  --off-black: #4a443d;
  --template-bg: #f8ecec;
  --template-border: #dcbcd9;
  --template: #9a5b92;
  --lavender: #b6bcf2;
  --dark-lavender: #6960cb;
  --sky-blue: #e4f0ec;
  --light-green: orange;

  --auto-dark: #35a4ab;
  --auto-border: #92dfd6;
  --non-auto-bg: var(--light);
  --auto-team-border: var(--lavender);
  --auto-team-bg: var(--sky-blue);
  --auto-user-bg: var(--light-green);
  --sked-color-block: var(--dark-lavender);

  --nav-selected-text: var(--dark);
  --nav-selected-bckrnd: var(--tan-med);
  --nav-deselected-text: var(--brown-med);

  --ophanim-final-light: var(--light);
  --ophanim-final-auto-border: var(--auto-border);
  --ophanim-final-auto-bg: var(--light-green);
  --ophanim-final-dark: var(--dark);
  --ophanim-final-brown-med: var(--brown-med);
  --ophanim-final-border: var(--border);
  --ophanim-final-orange: var(--alt-orange);
  --ophanim-final-red: var(--alt-red);
}
