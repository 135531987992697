@import 'src/styles/_variables';

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    border-radius: 20px !important;
    padding: 20px;
    background-color: #faf7ed !important;
  }
}

.schedule-project-dialog {
  width: 100vw !important;
  max-width: 720px !important;
  max-height: 100% !important;
}

@media (max-width: $task-dialog-mobile-breakpoint) {
  .schedule-project-dialog {
    height: 100% !important;
    max-width: 100vw !important;
  }
}

@media (max-width: 386px) {
  .autosked-modal .mdc-dialog__surface {
    padding: 8px 8px 20px 8px;
  }
}

.autosked-modal .mdc-dialog__surface {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #92dfd6; //Change color of different views
  background-color: #ebf0e4;
}

@media (max-width: 386px) {
  .autosked-modal .mdc-dialog__surface {
    padding: 8px 8px 20px 8px;
  }
}

.autosked-modal {
  max-width: 720px !important;
  width: 100vw !important;
}

.task-dialog {
  width: 100vw !important;
  max-width: 720px !important;
  max-height: 100% !important;
  height: 706px !important;
}

.task-wizard-dialog {
  width: 100vw !important;
  max-width: 720px !important;
  max-height: 100% !important;
  height: 706px !important;
}

.project-selector-dialog {
  width: 100vw !important;
  max-width: 720px !important;
  max-height: 100% !important;
  height: 530px !important;
}

.add-metric-dialog {
  width: 100vw !important;
  max-width: 440px !important;
}

@media (max-width: 450px) {
  .project-selector-dialog {
    .mat-mdc-dialog-surface {
      padding: 0 !important;
    }
  }
}

.create-project-dialog {
  width: 100vw !important;
  max-width: 720px !important;
  max-height: 100% !important;
  height: 530px !important;
}

@media (max-width: $task-dialog-mobile-breakpoint) {
  .task-dialog,
  .task-wizard-dialog,
  .autosked-modal,
  .project-selector-dialog,
  .create-project-dialog {
    height: 100% !important;

    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }

  .task-wizard-dialog {
    max-width: 100vw !important;
    height: 100% !important;

    mat-dialog-container {
      max-height: 100% !important;
    }
  }
}

@media (max-width: 600px) {
  .goals-target-dialog {
    height: 100% !important;

    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
      padding: 20px !important;
    }
  }
}

@media (max-width: 450px) {
  .task-dialog {
    .mat-mdc-dialog-surface {
      padding: 10px;
    }
  }
}

@media (max-width: 386px) {
  .task-wizard-dialog .mdc-dialog__surface {
    padding: 12px;
  }
}

.info-confirm-dialog {
  width: 415px;
}

.goals-target-dialog {
  .mat-mdc-dialog-surface {
    padding: 35px;
  }
}

.goals-objective-dialog {
  width: 90%;
  max-width: 520px !important;
}

.goals-connect-target-dialog {
  width: 100%;
  max-width: 520px !important;
  height: 100%;
  max-height: 590px;
}

@media (max-width: 520px) {
  .goals-connect-target-dialog {
    height: 100% !important;
    max-height: 100%;

    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
    }
  }
}
