@import 'src/styles/_variables';

.fc-event {
  .move-icon {
    display: none;
  }

  .colors-wrap {
    display: none;
  }

  .options-container {
    display: none;
  }
}

.fc-custom-event-class {
  border-radius: 6px;
  border: 2px solid var(--auto-border);
  background: var(--non-auto-bg);

  &.auto-team {
    border: 2px solid var(--auto-team-border);
    background: var(--auto-team-bg);
  }

  &.auto-user {
    border: 2px solid var(--auto-border);
    background: var(--auto-user-bg);
  }

  &.edited {
    border-style: dashed;
  }

  .fc-event-main {
    .custom-event-body {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding: 6px 8px;
      overflow: hidden;
      cursor: pointer;

      .title {
        margin: 0 0 3px 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
      }

      .times {
        margin: 0 0 3px 0;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
      }

      .color-wrapper {
        display: flex;
        gap: 5px;

        .color-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          border-radius: 2px;

          span {
            margin: 0 0 2px 0;
            color: var(--sked-color-block);
          }
        }
      }
    }
  }
}

$row-gap: 10px;
.schedule-month-calendar {
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }
  .fc-scrollgrid {
    border: none;

    thead {
      display: none;
    }
  }

  .fc-scrollgrid-sync-table {
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: none;
    }

    .fc-daygrid-day-frame {
      border-left: 1px solid #faf7ed;
      background: var(--ophanim-final-lightest, #fefdf5);
      min-height: 133px;
      height: 100%;
      padding-bottom: 10px;
    }

    .fc-day-today {
      .fc-daygrid-day-frame {
        border-left: 1px solid #faf7ed;
        background-color: rgba(#eb7a3b, 0.1);
        min-height: 133px;
        height: 100%;
        padding-bottom: 10px;
      }
    }

    .fc-day-other {
      .fc-daygrid-day-frame {
        background: var(--ophanim-final-lightest, #fefdf5);
        opacity: 0.7;
      }
    }

    td {
      border: none;
      border-bottom: 10px solid var(--ophanim-final-tan-med, #f0ecdd);

      &:first-child {
        .fc-daygrid-day-frame {
          border-radius: 8px 0 0 8px;
        }
      }

      &:last-child {
        .fc-daygrid-day-frame {
          border-radius: 0 8px 8px 0;
        }
      }
    }

    .fc-daygrid-day-number {
      color: var(--Black--Foreground-64, rgba(47, 39, 35, 0.64));
      font-family: Asap;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 1.2px;
      margin-top: 5px;
      margin-left: 7px;
    }
  }

  .fc .fc-daygrid-day-top {
    flex-direction: row;
  }

  .fc-event-selected,
  .fc-event:focus {
    box-shadow: none;
    background: transparent;
  }

  .fc-daygrid-dot-event.fc-event-mirror,
  .fc-daygrid-dot-event:hover {
    background: transparent;
  }

  .fc-scroller::-webkit-scrollbar {
    width: 3px;
    margin-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fc-scroller::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #c7c2b6;
  }

  .fc-scroller::-webkit-scrollbar-thumb {
    background-color: #7c655f;
    border-radius: 4px;
  }

  .week-row {
    position: relative;
  }

  .week-row:hover .schedule-week-edit-button {
    display: flex;
  }

  .week-row {
    &:first-child {
      .schedule-week-edit-button {
        margin-top: -2.3px;
        height: 94%;
      }
    }

    &:last-child {
      .schedule-week-edit-button {
        margin-top: 2px;
        height: 94%;
      }
    }
  }
}

.full-calendar-week {
  .fc-scrollgrid {
    border-top: none;
  }

  .fc-header-toolbar {
    margin-bottom: 0 !important;
  }

  .fc-col-header-cell {
    border-color: #f0ecdd;
  }

  .fc-timegrid-slot {
    border-top: none;
    border-left: 1px solid #e2ded5;
    border-right: 1px solid #e2ded5;
  }

  .fc-timegrid-slot-minor {
    border-top-style: none;
  }

  .fc-timegrid-slot-label {
    color: #7a746f;
    text-align: right;
    font-family: Asap;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .fc-col-header-cell-cushion {
    width: 100%;
    background: #e2ded5;

    .fc-week-view-header {
      display: flex;
      justify-content: space-between;
      padding: 8px 15px;

      color: #7a746f;
      font-family: Asap;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 133.333%;
      text-transform: uppercase;
    }
  }
}
