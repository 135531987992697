project-view-tracker {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-expansion-panel {
    background: var(--lightest);
    border-radius: 8px;
    transition: ease 0.3s;
    min-width: 0;
    width: 100%;
  }

  .mat-expansion-panel-header {
    padding: 10px;
    display: flex;
    height: 50px;
    min-width: 0;
  }

  .mat-expansion-panel-header-title {
    color: var(--brown-med);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    min-width: 0;
    margin-right: 0;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 50px;
  }

  .mat-expansion-panel-header.mat-expanded,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: var(--white);
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: var(--white);
  }

  .mat-expansion-panel-body {
    padding: 10px;

    p {
      margin-bottom: 0;
      color: var(--light-theme-foreground-87, rgba(47, 39, 35, 0.87));
      font-size: 16px;
      line-height: 24px;
    }
  }

  .mat-content.mat-content-hide-toggle {
    margin-right: 0;
  }
}
