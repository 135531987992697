@import 'theme-variables';

@media (max-width: 959px) {
  .light-theme {
    &.mat-app-background {
      background-color: #faf7ed !important;
    }
  }
}

.light-theme {
  @include light-theme-colors;

  &.mat-app-background {
    font-family: 'Asap', serif;
    background-color: var(--light);
    color: rgba(47, 39, 35, 0.87);

    .mat-drawer-container {
      background-color: #faf7ed;
    }
  }
}
