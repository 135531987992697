@import 'theme-variables';

.dark-theme {
  @include dark-theme-colors;

  &.mat-app-background {
    font-family: 'Asap', serif;
    background-color: red;
    color: rgba(47, 39, 35, 0.87);

    .mat-drawer-container {
      background-color: #faf7ed;
    }
  }
}
